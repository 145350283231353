import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div>
            <section className="py-6 mt-24 bg-gray-100">
                <div className="container mx-auto px-0 my-0">
                    <div className=" flex flex-col md:flex-row my-1 bg-light mx-auto rounded-xl">
                        <div className="md:flex-col w-[95%] md:w-[45%] text-center md:ml-12 mt-4 p-2">
                            <img className="w-auto bg-white max-h-[264px] rounded-3xl px-4 mx-auto mb-2"
                                src='/images/faisal.png' alt='Dr. Shahfaisal Saifee' />
                            <h3 className='font-bold text-2xl gradient-text'>Dr. Shahfaisal Saifee (PT)</h3>
                            <p className='font-serif text-sm'>BPTh, MPTh, MBA Healthcare Management (IIM) Lucknow</p>
                            <p className='font-serif text-sm'>11+ years of Experience</p>
                            <p className='font-serif text-sm'>Currently Leading Department of Quality and Training at a Healthcare Company</p>
                        </div>
                        <div className="flex-col w-full md:w-[55%] mt-4 pt-4">
                            <h1 className="text-2xl gradient-text mx-6 mb-2 font-bold">Welcome to SFS Academy</h1>
                            <p className="text-gray-600 font-semibold md:text-xl mx-4 sm:mx-8 md:mr-12">
                                It is an online academy which serves as a specialized platform dedicated to the comprehensive education and training of aspiring Physiotherapists in clinical
                                and academics.<br />Our Academy offers a program that is as per the compiled blueprints of International standards which creates a pathway to achieve success in
                                becoming a licensed PT across the globe. Our academy focuses on enhancing Physiotherapy knowledge for better strategies in Rehabilitation.
                            </p>
                            <div className="mt-4 text-center">
                                <Link to="/registration">
                                    <button className="bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-700 transition-colors">
                                        Enroll Now
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container mx-auto my-2 p-2">
                <div className="flex flex-col md:flex-row rounded-2xl"> {/*bg-blue-900 */}
                    <div className="flex-col order-2 md:w-1/2 md:order-1 md:ml-14 md:mt-8 font-semibold">
                            <h1 className='text-4xl gradient-text mb-4'>Why choose us?</h1>
                            <ul className="list-disc ml-5 space-y-1">
                                <li>Our dedicated and highly skilled staff have good clinical exposure.</li>
                                <li>A thorough understanding of what is required for enhancing clinical practice.</li>
                                <li>Our founder is relentless and enthusiastic at resolving issues and doubts both inside and outside the classroom.</li>
                                <li>We also emphasize sharpening the management skills of our aspirants.</li>
                                <li>We believe in bridging the gap between your academic and clinical knowledge.</li>
                            </ul>
                    </div>
                    <div className="sm:w-1/3 md:w-1/2 order-1 md:order-2 content-center mx-4 sm:mr-8 md:mr-12 mb-4">
                        <img src='/images/womanphysio.webp' alt="Physiotherapy1" className="w-5/6 max-h-80 rounded-2xl mx-auto mb-2" />
                    </div>
                </div>
            </div>
            <div className="container mx-auto my-2 px-4">
                <div className="flex flex-col md:flex-row rounded-2xl m-2">
                    <div className="sm:w-1/3 md:w-1/2 order-1 mx-4 content-center md:ml-12">
                        <img src='/images/phisioimg.webp' alt="Physiotherapy2" className="w-auto max-h-72 rounded-xl" />
                    </div>
                    <div className="flex order-2 md:w-1/2 md:mr-12 font-semibold">
                        <div>
                            <h1 className='text-4xl mt-2 gradient-text p-2'>Enhancing your Physiotherapy</h1>
                            {/* <p className="text-left mx-4 sm:mx-8 md:mx-24"> */}
                            <p className='mt-2'>
                                We offer variety of Resources, Live Lectures, Structured Study Material, Question solving
                                strategies which will contain academic and practical insights. Our high quality and affordable online tutoring
                                is provided by proficient tutors with a long standing clinical experience. Content is well curated and paced. A new perspective with clinical relevance will be a good add on to clinical practice.
                            </p>
                            <div className="gradient-text flex flex-row gap-4">
                                <div className="w-full lg:w-1/2 text-center">
                                    <h3 className="text-xl font-semibold text-sky-700">Interactive</h3>
                                    <h1 className="text-2xl font-extrabold text-sky-700">Online</h1>
                                    <h3 className="text-xl font-semibold text-sky-700">Lectures</h3>
                                </div>
                                <div className="w-full lg:w-1/2 text-center">
                                    <h3 className="text-xl font-semibold text-sky-700">Trained</h3>
                                    <h1 className="text-2xl font-extrabold text-sky-700">150+</h1>
                                    <h3 className="text-xl font-semibold text-sky-700">Physiotherapists</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rounded-3xl mx-2 md:mx-6 p-6 m-4" style={{ background: "linear-gradient(130deg,#0800ff, #00aaff)"}}>
                {/* <div className="flex flex-wrap m-8"> */}
                <div className="w-full text-center ">
                    <h1 className="text-4xl font-semibold text-white mb-5">SFS Academy Offers</h1>
                    <ul className="flex flex-wrap justify-center gap-1">
                        {[
                            "Musculoskeletal",
                            "Cardio Pulmonary and Vascular",
                            "Neurology (Adult and Pediatrics)",
                            "Community Rehabilitation (Women's Health, Geriatrics, Ergonomics)",
                            "Manual Therapy",
                            "Psychology (Human Behaviour Analysis)",
                            "Gait/Posture",
                            "Non-System and Other System Conditions",
                            "Anatomy / Physiology / Bio-Mechanics",
                            "Ethics",
                            "Treatment Theories and Strategies",
                            "Electro Therapy",
                            "Infection Control",
                            "Indications and Contra-Indications"
                        ].map((item, index) => (
                            <li
                                key={index}
                                className="bg-green-500 text-white rounded-lg p-1 m-1 hover:font-semibold transition-transform transform hover:scale-105"
                            >
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
                {/* <div className="w-full md:w-1/2 flex flex-col justify-center items-center">
                        <h2 className="text-xl font-semibold">Schedule Your Free</h2>
                        <h2 className="text-xl font-bold ">Counselling for clearing any doubts</h2>
                        <div className="p-4">
                            // {/* <CounsellingForm /> 
                        </div>
                    </div> */}
                {/* </div> */}
            </div>
        </div>
    );
};

export default Home;