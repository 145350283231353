import React from "react";
import { FaFacebook, FaLinkedin, FaInstagram, FaWhatsapp, FaPhone, FaEnvelope } from "react-icons/fa";
import { IoLocationOutline } from 'react-icons/io5';

const Footer = () => {
    const emailAddress = 'support@sfsacademy.in';
    const phoneNumber = '+91 8983019596';

    return (
        <footer id="contact" className="bg-sky-100 text-gray-900 py-8">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap mx-2 justify-between">
                    <div className='w-full md:w-[12%] items-center mb-4 mx-4'>
                        <img src='/logo.png' className='rounded-lg h-14 m-0 pr-0' alt="SFSAcademy logo" />
                    </div>
                    <div className="w-full md:w-1/4 mb-4">
                        <h2 className="text-xl font-bold ml-4 mb-1">Contact Us</h2>
                        <ul className="space-y-2">
                            <li className="flex items-center"><FaEnvelope className="mr-2" />
                                <a href={`mailto:${emailAddress}`} className="hover:underline">{emailAddress}</a></li>
                            <li className="flex items-center"><FaPhone className="mr-2" />
                                <a href={`tel:${phoneNumber}`} className="hover:underline">{phoneNumber}</a></li>
                            <li className="flex items-center"><IoLocationOutline className="mr-2" /> PCMC, Maharashtra, India</li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/4 mb-4">
                        <h2 className="text-xl font-bold mb-2">Follow Us</h2>
                        <div className="flex space-x-4">
                            {/* <a href="https://www.facebook.com/" className="hover:text-blue-900"><FaFacebook size={30} /></a> */}
                            <a href="https://www.linkedin.com/in/sfs-academy-a57b762a4" className="hover:text-blue-800"><FaLinkedin size={30} /></a>
                            <a href="https://www.instagram.com/sfs.academy" className="hover:text-fuchsia-700"><FaInstagram size={30} /></a>
                            <a href="https://api.whatsapp.com/send?phone=918983019596" className="hover:text-green-600"><FaWhatsapp size={30} /></a>
                        </div>
                    </div>
                    <div className="w-full md:w-1/4 mb-4">
                        <h2 className="text-xl font-bold mb-2">Legal</h2>
                        <div className="space-y-4">
                            <a href="/termsandcondition" className="hover:text-blue-800">
                                Terms And Conditions | Privacy Policy | Cancellation Policy | Refund Policy</a>
                        </div>
                    </div>
                </div>
                <div className="mt-4 text-center text-gray-500">
                    &copy; {new Date().getFullYear()} SFS Academy. All rights reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;