import React from "react";
import { FaStar } from 'react-icons/fa';

const Reviews = () => {
    const writtenReviews = [
        {
            studentName: 'Dr. Adarsh Pathakd',
            rating: 5,
            text: 'It was amazing learning experience with Dr. Faisal sir. Dr. Faisal Saifee helped me personally to making a protocol for cognitive therapy in very easy way without any heavy concepts and theory it was very helpful to me and patient as well so Thanku sir and congratulations for your valuable guidance. It is Very helpful to making new protocols for operative non operative & neuro cases, assessing the patient.',
        },
        {
            studentName: 'Prachi Bansal',
            rating: 4,
            text: 'It is very on point with his own real life patients review and feedbacks. Helps you relate quite easily to even your pts current status.',
        },
        {
            studentName: 'Anjuna Nair',
            rating: 5,
            text: "It's has really helped me alot and showed me about the concepts I should be focusing on, try to be best in, and getting more into detailed analysis of small things. Technique of teaching by sir helped us to get the best direction of thinking and work as that and many more it's really fun and completely knowledgeable sessions with sir and looking forward for more such sessions. It have really helped me in thinking in different perspective and think more about one concepts and utilise small things in making big differences ,sir have showed the way to see all other sides of one thing and later choose best one to use it for better results.",
        },
        {
            studentName: 'Yogita Salgaonkar',
            rating: 5,
            text: 'Dr Shahfaisal has different approach towards the teaching .with little humour he keeps the class engaged as well as he teaches the diagnosis ,treatment  management  according to his experience in abroad about so we get the broader idea .which is new and interesting to learn eccentric exercise brushed up and so i am using them in my clinical practice everyday more often.',
        },
        {
            studentName: 'Chetan Dhote',
            rating: 4,
            text: "It's interesting Gives a better perspective.",
        },
        {
            studentName: 'Monica Antony',
            rating: 5,
            text: 'Found it very helpful and something out of the box which actually made us kept thinking. Liked the way where there was two way interaction. It has definitely helped me a lot Instead of just sticking to the basic protocol I have been able to make a defined protocol for the patient with proper reasoning.',
        },
        {
            studentName: 'Kiran Agam',
            rating: 5,
            text: 'Very informative and easy understand all the concept in simple language With his special techniques recovery gets more faster than usual and more accurate for specific points.',
        },
        {
            studentName: 'Kolpe Monu',
            rating: 5,
            text: "I appreciate Sir's teaching . His explanations are concise , making complex concepts easier to grasp.The use of the real life example enhance my understanding of the subject. It helped me to have clear understanding of biomechanics and how to apply it on daily practice.",
        },
        {
            studentName: 'Akshada Gugale',
            rating: 5,
            text: "It was really good. It's really helping me in faster recovery of patients Modified way of static quads and Heel slides help to regain strength on a faster rate as compare to the traditional method.",
        },
        {
            studentName: 'Mulchandani Dimple',
            rating: 5,
            text: 'The conceptual training is really great, it is always to the point and that helps us to understand the condition of the patient better and also helps in diagnosing the problem very easily. It helps me in understanding the problem very precisely and also it has helped me in designing the protocol very easily.',
        },
        {
            studentName: 'Mankar Shruti',
            rating: 5,
            text: 'I found the training very helpful and innovative. Very different and goal oriented unlike what is taught in the classroom lectures. Many concepts taught by Dr. Faisal during our training sessions our useful in our day to day practice. For example: Importance of eccentric training, core and trunk stabilizing exercises for SCI patients, variations of VMO and Heel slides which are way more effective than our mainstream exercises..',
        },
        {
            studentName: 'arssk',
            rating: 5,
            text: 'Very informative and new concept. It helped me frame my rehab in a better manner keeping in mind the functional goals and probable results that can be achieved based on the insights taught during his training.',
        },
        {
            studentName: 'viral.k.gareja',
            rating: 5,
            text: 'Dr.shahfaisal saifee provide very informative clinical reasoning which will help us in regular practice to make decision about difficult cases.',
        },
        {
            studentName: 'waghsailee',
            rating: 5,
            text: 'To take such learning and interactive sessions often by you sir.. after along time brushed up the knowledge.',
        },
        {
            studentName: 'ruchihakani',
            rating: 5,
            text: 'The training was very helpful for our daily practice. It was very conceptual and that was the best part. In addition to that we also got to learn manipulation which was a plus. Only one suggestion that training should be digitalize(online training).',
        },
        {
            studentName: 'khushbunanda',
            rating: 5,
            text: 'Dr.shahfaisal saifee sir, explain everything in reliable way.',
        },
        {
            studentName: 'preeti.shrisunder',
            rating: 5,
            text: 'Loved the training.<br /> Had opportunities to learn alot.',
        },
        {
            studentName: 'anjunanair',
            rating: 5,
            text: 'The session was absolutely super and interactive ,would like to her more the most interesting part was Q and A where everyones perception was measured and sir gave us a path....great 👍👍👍.',
        },
        {
            studentName: 'dr.tanayamhatre',
            rating: 5,
            text: 'It was very educational and thought channeling It helped in setting up better protocol for the patient with better outcomes.',
        },
        {
            studentName: 'aleememon',
            rating: 5,
            text: 'The session highlighted concepts that are not extensively taught academically but has clinical relevance and applications. Using case examples in between made it easier to relate. To be more vigilant about the biomechanical aspects of movement and how it can benefit clinical outcomes.',
        },
        {
            studentName: 'srushtibelekar',
            rating: 5,
            text: 'Beneficial in my practice Helped in differential diagnosis and specificity in management.',
        },
        {
            studentName: 'vicharea',
            rating: 5,
            text: 'I found teaching to be incredibly enlightening. His approach made complex ideas clearer and more accessible. Training has greatly enhanced my problem-solving abilities. Understanding the core principles allows me to approach challenges more strategically and find innovative solutions in my day-to-day work.',
        },
        {
            studentName: 'gujarathitulseetrg@gmail.com',
            rating: 5,
            text: 'It was good , quite different. It has helped me to have  a different outlook towards different conditions and make treatment techniques easier.',
        },
        {
            studentName: 'farhathaindade',
            rating: 5,
            text: 'Very informative, Implications could be made in diagnosing faster.',
        },
        {
            studentName: 'muskan',
            rating: 5,
            text: 'Very unique and easy to learn It has helped me to improve assessment and diagnosis.',
        },
        {
            studentName: 'sanikasawant',
            rating: 5,
            text: 'Very interesting and practical based concepts and hands on skills got cleared. To a very good extent!',
        },
        {
            studentName: 'twinkle04.m@gmail.com',
            rating: 5,
            text: 'Found Directional approach in many cases on field. Helpful and innovative in practical aspects.',
        },
        {
            studentName: 'muthiyankhushbo',
            rating: 5,
            text: 'Very well informative and focused on PT keypoints which are needed in practical Helped in modifying treatment plans and protocol.',
        },
    ];

    return (
        <div className="container mx-auto mt-28 p-2">
            <div className="text-center">
                <h2 className="text-3xl gradient-text font-bold mb-6 text-center">Reviews</h2>
                <h3 className="text-2xl gradient-text font-semibold mb-4">Here is our students Experience with us</h3>
                <section>
                    {/* <h2 className="text-2xl font-semibold mb-4">Written Reviews</h2> */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-2 gap-6">
                        {writtenReviews.map((review, index) => (
                            <div key={index} className="bg-white p-6 shadow-lg rounded-lg">
                                <div className="flex flex-col md:flex-row justify-between items-center mb-2">
                                    <h3 className="text-xl gradient-text font-semibold mr-4">{review.studentName}</h3>
                                    <div className="flex p-1">
                                        {Array.from({ length: review.rating }).map((_, starIndex) => (
                                            <FaStar key={starIndex} className="text-yellow-500" />
                                        ))}
                                    </div>
                                </div>
                                <p className="mt-4">{review.text}</p>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Reviews;