import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

const Course = () => {
    const navigate = useNavigate();
    const [isChecked, setChecked] = useState(false);

    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };

    const handleEnrollment = () => {
        if (isChecked) {
            handleGotoRegister();
        } else {
            alert("Please agree to the terms and conditions to proceed.");
        }
    };

    const handleGotoRegister = () => {
        navigate('/registration');
    };

    return (
        <>
            <div className="container mt-24 mx-auto p-4">
                <div className="flex flex-col items-center">
                    <div className="text-white p-4 m-4 rounded-lg w-full md:w-3/4 lg:w-1/2"  style={{ background: "linear-gradient(130deg,#0800ff, #00aaff)"}}>
                        <h1 className="text-2xl font-bold mb-2">Course Name</h1>
                        <p>Physiotherapy Tutorial Program</p>
                        <p>[4 months live lectures (Program accessible for one year)]</p>
                        <h2 className="text-xl font-semibold mt-4">Description</h2>
                        <p>It covers all specialities of physical therapy components of academic, clinical and recent advances.</p>
                        <h2 className="text-xl font-semibold mt-4">Eligibility Criteria:</h2>
                        <p>Third Year, Final Year, Bachelors & Masters of Physiotherapy all are eligible for joining this online tutorial.</p>
                    </div>
                    <div className="bg-orange-100 p-4 m-4 rounded-lg flex flex-col items-center md:w-3/4 lg:w-1/2">
                        <div className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                id="termsCheckbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                className="mr-2"
                            />
                            <a href='/termsandcondition' className="text-wrap" htmlFor="termsCheckbox">
                                I agree to the terms and conditions and give my consent to pay the fees eligible for this program.
                            </a>
                            <span className="text-red-500">*</span>
                        </div>
                    </div>
                    <div className="bg-green-600 text-white p-4 m-4 rounded-lg w-full md:w-3/5 lg:w-2/5">
                        <h2 className="text-xl text-center font-semibold">Fees Structure: ₹ 36,999/-</h2>
                    </div>
                    <div className="mb-4">
                        <button 
                            onClick={handleEnrollment}
                            className="bg-blue-600 text-white py-2 px-6 rounded-full transition duration-300 transform hover:bg-white hover:text-blue-600 hover:scale-105 shadow-lg">
                            Enroll now for the course and proceed with payment
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Course;